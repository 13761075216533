import React from 'react'
import Menu from "./Menu";
import Stopka from "./Stopka";
import TowaryLista from "./TowaryLista";
import Koszyk from "./Koszyk";
import {getKonfig, getKoszyk, getRazem} from "../../Serwis";
import moment from 'moment'
import 'moment/locale/pl';
import InfoPanel from "./InfoPanel";

export default class Panel extends React.Component {

    constructor(props) {
        super(props);

        this.state= {
            pobrano: false,
            dodaj: false,
            razem: '0',
            towary:[],
            tek: false, // termin edycji koszyka
            termin: '',
            stop: '',
            diler: false
        }
    }

    componentDidMount() {
        this.odswiezkoszyk();
        this.pobierztek();

        console.log('Dzisiaj '+moment().format('YYYY-MM-DD'));
        //console.log(moment(this.state.termin).format('DD.MM.YYYY'));
    }

    componentWillUnmount() {
        window.clearInterval(this.timerId);
    }

    updateDate() {
        this.setState({
            stop: moment(moment(this.state.termin)-moment())
        });

        //console.log(moment(moment('2020-12-14T22:59:59+01:00')-moment()).format());
    }

    pobierztek(){
        getKonfig('Termin skladania zamowien').then((response) => {
            if (response && response.status < 300) {

                //console.log(response.data[0]);
                //console.log(moment(response.data[0].WARTOSC));
                if((moment().format('YYYY-MM-DD') <= (moment(response.data[0].WARTOSC).format('YYYY-MM-DD'))))
                {
                    this.setState({tek: true})
                }
                else {
                    this.setState({tek: false})
                }

                if((moment().format('YYYY-MM-DD') === (moment(response.data[0].WARTOSC).format('YYYY-MM-DD'))))
                {
                    this.timerId = window.setInterval(this.updateDate.bind(this), 1000);
                }

                this.setState({termin: moment(response.data[0].WARTOSC+' 22:59:00').format('YYYY-MM-DD HH:mm')})

            } else {
                console.log('error1 konfig')
            }
        }).catch((error) => {console.log('error2 konfig')})
    }


    pobierzkoszyk =()=>{
        getKoszyk().then((response) => {
            if (response && response.status < 300) {

                this.setState({towary: response.data, pobrano: true})

            } else {
                console.log('error1 koszyk')
            }
        }).catch((error) => {console.log('error2 koszyk')})
    }

    pobierzrazem =()=>{
        getRazem(JSON.parse(sessionStorage.sesjaUzytkownika).numer).then((response) => {
            if (response && response.status < 300) {

                this.setState({razem: response.data})

            } else {
                console.log('error1 razem')
            }
        }).catch((error) => {console.log('error2 razem')})
    }

    odswiezkoszyk =()=>{
        this.pobierzkoszyk();
        this.pobierzrazem();
    }

    pokazukryjdodaj=()=>{
        this.setState({dodaj: !this.state.dodaj});
        //console.log(moment(this.state.termin).format('DD.MM.YYYY HH:mm'));
        //console.log(moment(this.state.termin).endOf('hours').fromNow());
        //console.log(moment(moment(this.state.termin)-moment()).format('YYYY.MM.DD HH:mm'));
        //console.log(moment().format('DD.MM.YYYY HH:mm:ss'));
        //console.log(moment(moment(this.state.termin)-moment()).format('HH:mm:ss'));
    }

    render(){
        const { pobrano, towary, razem, tek, dodaj, termin, stop} = this.state;
        return <>
            <Menu pokazukryjdodaj={this.pokazukryjdodaj} btext={'Towary'} edycja={tek}/>

                <div className={'main'}>

                    {(tek)?
                        <>
                            <i id={'info'}>Twój koszyk zostanie zamknięty {moment(termin).format('DD.MM.YYYY')} o 23:59.</i>
                            <h3 style={{color: 'red'}}>{
                                (stop)?
                                    ((moment(stop).format('H')<=0)?
                                            (moment(stop).format('mm')<=0)?
                                                    (moment(stop).format('ss')<=0)?
                                                        'KOSZYK ZAMKNIĘTY'
                                                        :
                                                        (moment(stop).format('s'))
                                                :
                                                    (moment(stop).format('m')+' min ')
                                                    +
                                                    (moment(stop).format('s')+' s')
                                        :
                                            (moment(stop).format('H')+' godz ')
                                            +
                                            (moment(stop).format('m')+' min ')
                                            +
                                            (moment(stop).format('s')+' s'))
                                :
                                    ''}
                            </h3>
                        </>
                    :
                        <i id={'info'}>Twój koszyk został zamknięty w dniu {moment(termin).format('DD.MM.YYYY')}.</i>
                    }

                    {(dodaj)?
                        <TowaryLista odswiez={this.odswiezkoszyk} pokazukryjdodaj={this.pokazukryjdodaj}/>
                        :
                        <div></div>
                    }


                    <Koszyk towary={towary} pobrano={pobrano} razem={razem} odswiez={this.odswiezkoszyk} edycja={tek}/>

                    <InfoPanel>
                        {/*<p>Za zamówienie należy zapłacić w terminie do 3 dni ({moment(termin).add(3, 'days').format('DD.MM.YYYY')}) od zamknięcia koszyka.</p>*/}
                        <p style={{color: 'white'}}>Zamówienie należy opłacić gotówką (ew. Kontakt tel) <br/>Podane ceny są orientacyjne i mogą ulec zmianie (jest to od nas nie zależne)</p>
                        <p>W przypadku nie zapłacenia zamówienie nie będzie realizowane!</p>
                    </InfoPanel>
                </div>


            <Stopka title='KOSZYK'/>
        </>
    }
}
