import axios from "axios";

export const isLogged = () => {
    if (sessionStorage.sesjaUzytkownika !== undefined){
        return true
    } else {
        return false
    }
}

export const isAdmin = () => {
    if (JSON.parse(sessionStorage.sesjaUzytkownika).dostep === '1'){
        return true
    } else {
        return false
    }
}

export const login = (haslo, numer) => {
    return axios({
        method: 'POST',
        url: "https://darnatury.netserwer.pl/login.php",
        headers: {"Content-Type": "text/plain"},
        data: {
            nr: numer,
            pas: haslo
        }
    })
}

export const getKonfig = (pozycja) => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/konfig.php?pozycja="+pozycja
    })
}

export const ileidwkoszyki = (id) => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/ileidwkoszyki.php?id="+id
    })
}

export const getKoszykiCount = () => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/koszykicount.php"
    })
}

export const getRazem = (numer) => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/razem.php/?nr="+numer
    })
}

export const getRazemAdmin = () => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/adminrazem.php"
    })
}

export const getKoszyk = () => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/koszyki.php/?nr="+JSON.parse(sessionStorage.sesjaUzytkownika).numer
    })
}

export const getTowary = () => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/towary.php"
    })
}

export const getUzytkownicy = () => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/uzytkownicy.php"
    })
}

export const getAdminUzytkownicy = () => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/adminkoszyki.php"
    })
}

export const getAdminUzytkownikKoszyk = (numer) => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/adminuserkoszyk.php/?nr="+numer
    })
}

export const delTowar = (id, numer) => {
    return axios({
        method: 'delete',
        url: "https://darnatury.netserwer.pl/delete.php",
        headers: {"Content-Type": "text/plain"},
        data: {
            nr: numer,
            id: id
        }
    })
}

export const delTowarMag = (id) => {
    return axios({
        method: 'delete',
        url: "https://darnatury.netserwer.pl/deltowarmag.php",
        headers: {"Content-Type": "text/plain"},
        data: {
            id: id
        }
    })
}

export const delKoszykiAdmin = () => {
    return axios({
        method: 'delete',
        url: "https://darnatury.netserwer.pl/admindeletekoszyki.php",
        headers: {"Content-Type": "text/plain"}
    })
}

export const delUser = (numer) => {
    return axios({
        method: 'delete',
        url: "https://darnatury.netserwer.pl/deleteuser.php",
        headers: {"Content-Type": "text/plain"},
        data: {
            numer: numer
        }
    })
}

export const delUserKoszyk = (numer) => {
    return axios({
        method: 'delete',
        url: "https://darnatury.netserwer.pl/deleteuserkoszyk.php",
        headers: {"Content-Type": "text/plain"},
        data: {
            numer: numer
        }
    })
}

export const insTowar = (numer, id, ile) => {
    return axios({
        method: 'post',
        url: "https://darnatury.netserwer.pl/insert.php",
        headers: {"Content-Type": "text/plain"},
        data: {
            nr: numer,
            id: id,
            ile: ile
        }
    })
}

export const insTowarMag = (nazwa, cena) => {
    return axios({
        method: 'post',
        url: "https://darnatury.netserwer.pl/insertmag.php",
        headers: {"Content-Type": "text/plain"},
        data: {
            nazwa: nazwa,
            cena: cena
        }
    })
}

export const insUser = (imie, nazwisko, numer) => {
    return axios({
        method: 'post',
        url: "https://darnatury.netserwer.pl/insertuser.php",
        headers: {"Content-Type": "text/plain"},
        data: {
            imie: imie,
            nazwisko: nazwisko,
            numer: numer
        }
    })
}

export const zmienHaslo = (nowe) => {
    return axios({
        method: 'post',
        url: "https://darnatury.netserwer.pl/zmianahasla.php",
        headers: {"Content-Type": "text/plain"},
        data: {
            nr: JSON.parse(sessionStorage.sesjaUzytkownika).numer,
            nowe: nowe
        }
    })
}

export const getAdminSztuki = () => {
    return axios({
        method: 'get',
        url: "https://darnatury.netserwer.pl/adminsztuki.php"
    })
}

export const updateTek = (pozycja, wartosc) => {
    return axios({
        method: 'patch',
        url: "https://darnatury.netserwer.pl/updatekonfig.php",
        headers: {"Content-Type": "text/plain"},
        data: {
            pozycja: pozycja,
            wartosc: wartosc
        }
    })
}
