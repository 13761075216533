import React from 'react'
import Menu from "../panel/Menu";
import Stopka from "../panel/Stopka";
import {toast, ToastContainer} from "react-toastify";
import {
    delTowarMag, getKonfig, getKoszykiCount,
    getTowary, ileidwkoszyki,
    insTowarMag
} from "../../Serwis";
import Box from "./Box";
import moment from "moment";

export default class Magazyn extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            nazwa: '',
            cena: '',
            pobrano: false,
            edycja: false,
            koszykicount: '',
            towary: []
        }
    }

    componentDidMount() {
        console.log('pobieram towary z bazy');
        this.pobierztowary();
        //this.pobierzkoszykicount();
    }

    pobierzkoszykicount(){
        getKoszykiCount().then((response) => {
            if (response && response.status < 300) {

                this.setState({
                    koszykicount: response.data[0].COUNT
                })

            } else {console.log('error1 towary')}
        }).catch((error) => {console.log('error2 towary')})
    }

    pobierztowary(){
        getTowary().then((response) => {
            if (response && response.status < 300) {

                this.setState({
                    dodaj: false,
                    pobrano: true,
                    towary: response.data
                })

            } else {console.log('error1 towary')}
        }).catch((error) => {console.log('error2 towary')})
    }

    wybrany = towar =>{
        console.log(towar);
        this.setState({wybrano:true, wybranytowar: towar})
    }

    onUsun=(towar)=>{
        //alert(towar.ID)
        //this.pobierzkoszykicount()

        ileidwkoszyki(towar.ID).then((response) => {
            if (response && response.status < 300) {

                this.setState({
                    koszykicount: response.data[0].ILE
                })

                if(this.state.koszykicount==='0')
                {
                    delTowarMag(towar.ID).then((response) => {
                        if (response && response.status < 300) {

                            console.log('pomyslnie usunieto towar z magazynu')
                            toast.success("Usunięto towar z magazynu");
                            this.pobierztowary();

                        } else {console.log('error1 towary mag')}
                    }).catch((error) => {console.log('error2 towary mag')})
                }
                else
                {
                    toast.error("Nie można usunąć towaru. Jest on już w koszyku!");
                }


            } else {console.log('error1 towary')}
        }).catch((error) => {console.log('error2 towary')})

    }

    onDodaj=()=>{
        insTowarMag(this.state.nazwa, this.state.cena).then(res => {
            if (res.status === 200){

                this.setState({
                    dodaj: false
                });

                this.pobierztowary();
                toast.success("Dodano towar do magazynu");
                console.log('insert ok');

                //this.props.history.push('/')
            } else {
                console.log('error')
            }
        }).catch(error => {
            console.log('Zle dane logowania');
        });
    }

    onChangeInput=(e)=>{
        this.setState({[e.target.name]: e.target.value})
    }

    onZamknij=()=>{
        this.setState({dodaj: false})
    }

    render(){
        const { pobrano, towary, dodaj} = this.state;
        const listatowarow=towary.map((element)=>(
            <li key={element.ID} onClick={() => this.wybrany(element)}>
                <div className="element">
                    <i className="fa fa-trash" onClick={() => this.onUsun(element)}/>
                    <div className="naz"><h2>{element.NAZWA}</h2></div> <div className="censzt"><h5>{element.CENA} zł</h5></div>
                </div>
            </li>
        ));
        return <>
            <Menu btext={'Koszyk'}/>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className={'main'}>

                {(dodaj)?
                    <Box title='Dodaj towar' zamknij={this.onZamknij}>
                        <div id={'zmianahasla'}>
                            <div id={'in'}><label>Nazwa: <input type='text' name='nazwa' onChange={this.onChangeInput}/></label></div>
                            <div id={'in'}><label>Cena: <input type='number' name='cena' onChange={this.onChangeInput}/></label></div>
                        </div>
                        <div className="dodaj-button">
                            <button onClick={this.onDodaj}>Dodaj towar</button>
                        </div>
                    </Box>
                :
                <div></div>
                }

                <div className='kosz'>

                    <div className="kosznag">
                        <h3>Towary</h3>
                        <i id='dodaj' className="fa fa-plus" onClick={()=>this.setState({dodaj: true})}/>
                    </div>
                    {(pobrano)?
                        <div>
                            {(towary.length===0 )?
                                <h4>Brak towarów...</h4>
                                :
                                <ul>{listatowarow}</ul>
                            }
                        </div>
                        :
                        <h4>Ładowanie...</h4>
                    }
                </div>

            </div>

            <Stopka title='Magazyn'/>
        </>
    }
}
