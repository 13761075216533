import React from 'react';
import {withRouter} from "react-router-dom";
import {isAdmin} from "../../Serwis";

class Stopka extends React.Component {

    onUstawienia(){
        this.props.history.push('/ustawienia')
    }

    onAdminPanel(){
        this.props.history.push('/adminpanel')
    }

    onUzytkownicy(){
        this.props.history.push('/uzytkownicy')
    }

    onMagazyn(){
        this.props.history.push('/magazyn')
    }

    onTermin(){
        this.props.history.push('/termin')
    }
    
    //fa fa-desktop
    render() {
        return (
            <div className="stopka">
                {(isAdmin())?
                    <div>
                        <i id='admin' className="fa fa-line-chart" onClick={()=>this.onAdminPanel()}/>
                        <i id='admin' className="fa fa-users" onClick={()=>this.onUzytkownicy()}/>
                        <i id='admin' className="fa fa-cube" onClick={()=>this.onMagazyn()}/>
                        <i id='termin' className="fa fa-calendar" onClick={()=>this.onTermin()}/>
                    </div>
                    :
                    <div></div>
                }
                <h4>{this.props.title}</h4>
                <i className="fa fa-cog" onClick={()=>this.onUstawienia()}/>
            </div>
        );
    }
};

export default withRouter(props => <Stopka {...props}/>);
