import React from 'react'
import Menu from "../panel/Menu";
import Stopka from "../panel/Stopka";
import {toast, ToastContainer} from "react-toastify";
import {
    delUser, delUserKoszyk,
    getAdminSztuki,
    getAdminUzytkownicy,
    getAdminUzytkownikKoszyk, getRazem,
    getRazemAdmin,
    getUzytkownicy
} from "../../Serwis";
import Box from "./Box";
import Select from 'react-dropdown-select';
import {confirmAlert} from "react-confirm-alert";

export default class AdminPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            sztuki: [],
            razem: '0',
            razemnr: '0',
            pobrano: false,
            pobranokoszyk: false,
            uzytkownicy: [],
            towary:[],
            nrkoszyk: '',
            tek: ''
        };

    }

     componentDidMount() {
        this.odswiez();
    }

    odswiez(){
        this.pobierzAdminSztuki();
        this.pobierzRazem();
        this.pobierzuzytkownicy();
    }

    pobierzuzytkownicy(){
        getAdminUzytkownicy().then((response) => {
            if (response && response.status < 300) {

                this.setState({
                    pobrano: true,
                    uzytkownicy: response.data
                })

            } else {console.log('error1 towary')}
        }).catch((error) => {console.log('error2 towary')})
    }

    pobierzRazem(){
        getRazemAdmin().then((response) => {
            if (response && response.status < 300) {

                this.setState({razem: response.data[0].RAZEM})

            } else {
                console.log('error1 razem')
            }
        }).catch((error) => {console.log('error2 razem')})
    }

    pobierzAdminSztuki =()=>{
        getAdminSztuki().then((response) => {
            if (response && response.status < 300) {

                this.setState({sztuki: response.data})

            } else {
                console.log('error1 sztuki')
            }
        }).catch((error) => {console.log('error2 sztuki')})
    }

    wybrany = towar =>{
        //console.log(towar);
        console.log('towar');
        //this.setState({wybrano:true, wybranytowar: towar})
    }

    onKoszyk(element){
        console.log(element.IMIE)

        if(element.NUMER===this.state.nrkoszyk)
        {
            this.setState({nrkoszyk: ''});
        }
        else
        {
            this.setState({nrkoszyk: element.NUMER});
            this.setState({pobranokoszyk: false});

            getAdminUzytkownikKoszyk(element.NUMER).then((response) => {
                if (response && response.status < 300) {

                    this.setState({towary: response.data, pobranokoszyk: true})

                } else {
                    console.log('error1 koszyk')
                }
            }).catch((error) => {console.log('error2 koszyk')})

            getRazem(element.NUMER).then((response) => {
                if (response && response.status < 300) {

                    this.setState({razemnr: response.data[0].RAZEM})

                } else {
                    console.log('error1 razem')
                }
            }).catch((error) => {console.log('error2 razem')})
        }

    }

    usunKoszykUzytkownika = (user) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1 style={{textAlign: 'center'}}>Czy usunąć koszyk {user.IMIE} {user.NAZWISKO}?</h1>
                        <p style={{margin: '0 20px 20px 20px'}}>Spowoduje to usunięcie zawartości jego koszyka.</p>
                        <button style={{float: 'left', marginLeft: '20px'}} onClick={onClose}>NIE</button>
                        <button
                            style={{float: 'right', marginRight: '20px'}}
                            onClick={() => {
                                this.onUsunKoszyk(user);
                                onClose();
                            }}
                        >
                            TAK, usuń koszyk!
                        </button>
                    </div>
                );
            }
        });

    };

    onUsunKoszyk=(user)=>{

        delUserKoszyk(user.NUMER).then((response) => {
            if (response && response.status < 300) {

                console.log('pomyslnie usunieto koszyk uzytkownika')
                toast.success("Usunięto koszyk");
                this.odswiez();

            } else {console.log('error1 towary user')}
        }).catch((error) => {console.log('error2 towary user')})

    }

    render(){
        const { sztuki, uzytkownicy, towary, pobrano, pobranokoszyk, razemnr} = this.state;
        const listasztuki=sztuki.map((element)=>(
            <li id='lielement' key={element.ID}>
                <h5 id='nazwa'>{element.NAZWA}</h5>
                <h5 style={{textAlign: "right", width: '50px'}}>{element.SZTUK} szt</h5>
            </li>
        ));

        const towarylista=towary.map((element)=>(
            <li id='lielement' key={element.ID}>
                <h5 id='nazwa'>{element.NAZWA}</h5>
                <h5 style={{textAlign: "right", width: '50px'}}>{element.ILE} szt</h5>
            </li>
        ));

        const userlist=uzytkownicy.map((element)=>(
            <li key={element.NUMER} onClick={() => this.wybrany(element)}>
                <div className="element"><i id='koszyk' className="fa fa-shopping-basket" onClick={() => this.onKoszyk(element)}/><div className="naz"><h2>{element.IMIE} {element.NAZWISKO}</h2></div> <div className="censzt"><h5>tel: {element.NUMER}</h5></div></div>
                {(element.NUMER === this.state.nrkoszyk) ?
                    <div>
                        {(pobranokoszyk)?
                            <>
                            <div className='srodek'>
                                <ul id='ulkoszyk' style={{borderBottom: '1px solid #7f9dcb'}}>{towarylista}</ul>
                                <h5 style={{textAlign: "right", padding: '5px 0 10px 0', margin: '0 0 0 0', maxWidth: '260px'}}>{razemnr} zł</h5>
                            </div>
                            <div style={{width: '100%', textAlign: 'right', paddingBottom: '8px'}}>
                                <i id={'deleteKoszyk'} className="fa fa-trash" style={{marginRight: '15px'}} onClick={() => this.usunKoszykUzytkownika(element)}/>
                            </div>
                            </>
                            :
                                <h4>Ładowanie...</h4>
                        }
                    </div>
                    :
                    null
                }
            </li>
        ));

        return <>
            <Menu btext={'Koszyk'}/>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className={'main'}>

                <div className='kosz' style={{background: '#3c5a86', color: 'white'}}>

                    <div className="kosznag">
                        <h3>Podsumowanie</h3>
                    </div>
                    {(sztuki.length===0 )?
                        <h4>Koszyki są puste...</h4>
                        :
                        <ul id='ulkoszyk' style={{paddingBottom: '15px'}}>{listasztuki}</ul>
                    }
                </div>

                <div className='kosz' style={{background: '#3c5a86', color: 'white'}}>

                    <div className="kosznag">
                        <h3>Razem na kwotę</h3>
                    </div>
                    {(this.state.razem===null)?
                        <h2 style={{padding: '20px'}}>0 zł</h2>
                        :
                        <h2 style={{padding: '20px'}}>{this.state.razem} zł</h2>
                    }
                </div>

                <div className='kosz'>

                    <div className="kosznag">
                        <h3>Użytkownicy</h3>
                    </div>
                    {(pobrano)?
                        <div>
                            {(uzytkownicy.length===0 )?
                                <h4>Brak towarów...</h4>
                                :
                                <ul>{userlist}</ul>
                            }
                        </div>
                        :
                        <h4>Ładowanie...</h4>
                    }
                </div>


            </div>

            <Stopka title='ADMIN PANEL'/>
        </>
    }
}
