import React from 'react';
import {insTowar} from "../../Serwis";
import {toast, ToastContainer} from "react-toastify";

class Dodaj extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            ile: 1
        }
    }


    dodaj=()=>{
        console.log('dodaj');

        this.dodajdobazy();
    }

    dodajdobazy(){
        insTowar(JSON.parse(sessionStorage.sesjaUzytkownika).numer, this.props.wybranytowar.ID, this.state.ile).then(res => {
            if (res.status === 200){

                this.setState({
                    ile:1,
                });

                this.props.zamknijodswiez();
                toast.success("Dodano towar");
                navigator.vibrate(100);
                console.log('insert ok');

                //this.props.history.push('/')
            } else {
                console.log('error')
            }
        }).catch(error => {
            console.log('Zle dane logowania');
        });
    }

    ilosc = e => {
        this.setState({ile: e.target.value})
    }

    render(){
        return (
            <div className="dodaj">
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/*<h3>{this.props.wybranytowar.NAZWA}</h3>*/}

                <div className="dodaj-main">
                    <p>Podaj ilość: </p>
                    <input type="number" step="1" min="1" defaultValue = {this.state.ile} onChange={this.ilosc} required/>
                </div>
                <div className="dodaj-button">
                    <button id={'dodaj'} onClick={this.dodaj}><i className="fa fa-check"/>Dodaj</button>
                    <button id={'anuluj'} onClick={this.props.pokaz}><i className="fa fa-times"/>Anuluj</button>
                </div>

            </div>
        );
    }

};



export default Dodaj;
