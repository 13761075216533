import React from 'react';
import {delTowar} from "../../Serwis";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Koszyk extends React.Component {


    constructor(props) {
        super(props);
        this.state={
            wybrano: false,
            wybranytowar:[]
        };
        this.wybrany = this.wybrany.bind(this);
    }



    wybrany = towar =>{
        console.log(towar);
        this.setState({wybrano:true, wybranytowar: towar})
    }

    onUsun=(towar)=>{
        //alert(towar.ID)
        delTowar(towar.ID, JSON.parse(sessionStorage.sesjaUzytkownika).numer).then((response) => {
            if (response && response.status < 300) {

                console.log('pomyslnie usunieto towar')
                toast.success("Usunięto towar");
                this.props.odswiez();

            } else {console.log('error1 towary')}
        }).catch((error) => {console.log('error2 todwary')})
    }


    render(){
        const { pobrano, towary} = this.props;
        const listatowarow=towary.map((element)=>(
            <li onClick={() => this.wybrany(element)}>
                <div className="element">
                    {(this.props.edycja)?
                        <i className="fa fa-trash" onClick={() => this.onUsun(element)}/>
                    :
                        <div></div>
                    }
                    <div className="naz"><h2>{element.NAZWA}</h2></div>
                    <div className="censzt"><h5>{element.ILE} szt</h5> <h5>{element.CENA} zł</h5></div>
                </div>
            </li>
        ));

        return (
            <div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className='kosz'>

                    <div className="kosznag">
                        <h3>Koszyk</h3>
                        <div>
                            {(this.props.razem[0].RAZEM===null)?
                                <h5>Razem: 0 zł</h5>
                                :
                                <h5>Razem: {this.props.razem[0].RAZEM} zł</h5>
                            }
                        </div>
                    </div>
                    {(pobrano)?
                        <div>
                            {(towary.length===0 )?
                                <h4>Twój koszyk jest pusty...</h4>
                                :
                                <ul>{listatowarow}</ul>
                            }
                        </div>
                        :
                        <h4>Ładowanie...</h4>
                    }
                </div>
            </div>

        );
    }
};

export default Koszyk;
