import React from 'react';
import './menu.scss';
import {withRouter} from "react-router";

class Menu extends React.Component {

    onWyloguj(){
        sessionStorage.removeItem('sesjaUzytkownika')
        //window.location('/')
        //window.location.reload()
        window.location.replace('/')
        //this.props.history.push('/')
        console.log('wylogowano')
    }

    onDodaj(){
        if (this.props.btext==='Towary')
        {
            this.props.pokazukryjdodaj();
        }
        else {
            this.props.history.push('/')
        }

    }

    render(){
        return (

        <div className="topnav">

            <div className="topnav-centered">
                {/* <a href="#home" class="active">Home</a> */}
                <span>Witaj {JSON.parse(sessionStorage.sesjaUzytkownika).imie} {JSON.parse(sessionStorage.sesjaUzytkownika).nazwisko}</span>
            </div>

            {(this.props.edycja)?
                <button className="active" onClick={() => this.onDodaj()}>{this.props.btext}</button>
            :
                <div>
                    {(this.props.btext==='Towary')?
                        <div></div>
                        :
                        <button className="active" onClick={() => this.onDodaj()}>{this.props.btext}</button>
                    }
                </div>
            }

            <div className="topnav-right">
                <button className="wyloguj" onClick={() => this.onWyloguj()}>Wyloguj</button>
            </div>

        </div>
        );
    }

};


export default withRouter(props => <Menu {...props}/>);
