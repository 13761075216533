import React from 'react';

class InfoPanel extends React.Component {

    render(){
        return (
            <div className="info" style={{background: '#ff2323'}}>
                {this.props.children}
            </div>
        );
    }

};


export default InfoPanel;
