import React from 'react'
import Menu from "../panel/Menu";
import Stopka from "../panel/Stopka";
import {toast, ToastContainer} from "react-toastify";
import {delKoszykiAdmin, getKonfig, updateTek} from "../../Serwis";
import Box from "./Box";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default class Termin extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            tek: ''
        }
    }

    componentDidMount() {
        this.pobierzTermin();
    }

    pobierzTermin(){
        getKonfig('Termin skladania zamowien').then((response) => {
            if (response && response.status < 300) {

                console.log(response.data[0]);
                this.setState({tek: response.data[0].WARTOSC})

            } else {
                console.log('error1 konfig')
            }
        }).catch((error) => {console.log('error2 konfig')})
    }

    onChangeData(e){
        console.log(e)
        this.setState({tek: e})
    }

    onZapisz(){
        this.zapisztermin();
    }

    onZapiszWyzeruj(){
        console.log('Zapisz i wyzeruj');

        this.zapisztermin();
        this.wyzeruj();

    }

    zapisztermin(){
        updateTek('Termin skladania zamowien', this.state.tek).then((response) => {
            if (response && response.status < 300) {

                console.log('zapisano tek')
                toast.success("Zapisano termin");

            } else {console.log('error1 tek')}
        }).catch((error) => {console.log('error2 tek')})
    }

    usunkoszyki(){
        delKoszykiAdmin().then((response) => {
            if (response && response.status < 300) {

                console.log('pomyslnie usunieto koszyki')
                toast.success("Usunięto koszyki");
                this.props.odswiez();

            } else {console.log('error1 towary')}
        }).catch((error) => {console.log('error2 towary')})
    }

    wyzeruj = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1 style={{textAlign: 'center'}}>Zerowanie koszyków</h1>
                        <p style={{margin: '0 20px 20px 20px'}}>Spowoduje to usunięcie towarów ze wszystkich koszyków. Czy wyzerować koszyki?</p>
                        <button style={{float: 'left', marginLeft: '20px'}} onClick={onClose}>NIE</button>
                        <button
                            style={{float: 'right', marginRight: '20px'}}
                            onClick={() => {
                                this.usunkoszyki();
                                onClose();
                            }}
                        >
                            TAK, usuń koszyki!
                        </button>
                    </div>
                );
            }
        });

    };

    render(){
        return <>
            <Menu btext={'Koszyk'}/>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className={'main'}>

                <div className='kosz' style={{background: '#3c5a86', color: 'white'}}>
                    <div className="kosznag">
                        <h3>Termin edycji koszyka</h3>
                    </div>

                    <div id={'in'}><input className={'data'} type='date' value={this.state.tek} onChange={e => this.onChangeData(e.target.value)}/></div>

                    <div className="dodaj-button">
                        <button onClick={()=>this.onZapisz()}><i className="fa fa-floppy-o"/>Zapisz</button>
                        <button style={{float: 'left'}} onClick={()=>this.onZapiszWyzeruj()}><i className="fa fa-floppy-o"/>+<i style={{marginLeft: '10px'}} className="fa fa-trash"/>Zapisz i wyzeruj koszyki</button>
                    </div>
                </div>

                <div className='kosz' style={{background: '#3c5a86', color: 'white'}}>
                    <div className="kosznag">
                        <h3>KOSZYKI</h3>
                    </div>
                    <button className='kolorbutton'><i className="fa fa-lock"/>Zablokuj koszyki</button>
                    <button className='kolorbutton' onClick={()=>this.wyzeruj()}><i className="fa fa-trash"/>Wyzeruj koszyki</button>
                </div>

            </div>

            <Stopka title='ADMIN PANEL'/>
        </>
    }
}
