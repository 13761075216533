import React from 'react';
import Dodaj from './Dodaj';
import {getTowary} from "../../Serwis";

class TowaryLista extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            wybrano: false,
            pobrano: false,
            pokazdodaj: false,
            towary:[],
            wybranytowar:[]
        };
        this.wybrany = this.wybrany.bind(this);
    }

    componentDidMount() {
        console.log('pobieram towary z bazy');

        getTowary().then((response) => {
            if (response && response.status < 300) {

                this.setState({
                    pobrano:true,
                    towary:response.data
                })

            } else {console.log('error1 towary')}
        }).catch((error) => {console.log('error2 towary')})
    }

    wybrany = towar =>{
        console.log(towar.ID);
        this.setState({pokazdodaj:true, wybranytowar: towar})
    }
    zamknijpopup =()=>{
        this.setState({wybrano:false})
    }

    pokazukryjdodaj = () => {
        this.setState({
            pokazdodaj: !this.state.pokazdodaj
        })
    }

    zamknijpopupodswiez =()=>{
        this.setState({pokazdodaj:false});
        this.props.pokazukryjdodaj();
        this.props.odswiez();
    }


    render(){
        const { pobrano, towary, pokazdodaj, wybranytowar} = this.state;
        const listatowarow=towary.map((element)=>(
            <li key={element.ID} onClick={() => this.wybrany(element)}>
                <h5 id='nazwatowaru'>{element.NAZWA}</h5>
                <h5>{element.CENA} zł</h5>
            </li>
        ));

        return (
            <div>
                <div className="towary">
                    <div className="kosznag">
                        <h3>Lista towarów</h3><i className="fa fa-times" onClick={()=>this.props.pokazukryjdodaj()}/>
                    </div>
                    {(pobrano)?
                        <div>
                            <ul>{listatowarow}</ul>
                        </div>
                        :
                        <h4>Ładowanie...</h4>
                    }
                </div>

                {(pokazdodaj)?
                    <div className="towary">
                        <h3  className="kosznag" style={{color: '#83C322'}}>{wybranytowar.NAZWA} - {wybranytowar.CENA}zł</h3>
                        <Dodaj pokaz={this.pokazukryjdodaj} zamknijodswiez={this.zamknijpopupodswiez} wybranytowar={wybranytowar}/>
                    </div>
                    :
                    <div></div>
                }

            </div>

        );
    }

};

export default TowaryLista;
