import React from 'react'
import Menu from "../panel/Menu";
import Stopka from "../panel/Stopka";
import {toast, ToastContainer} from "react-toastify";
import {
    delUser, delUserKoszyk,
    getUzytkownicy,
    insUser
} from "../../Serwis";
import Box from "./Box";
import {confirmAlert} from "react-confirm-alert";

export default class Uzytkownicy extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            imie: '',
            nazwisko: '',
            numer: '',
            pobrano: false,
            dodaj: false,
            uzytkownicy: []
        }
    }

    componentDidMount() {
        console.log('pobieram towary z bazy');
        this.pobierztowary();
    }

    pobierztowary(){
        getUzytkownicy().then((response) => {
            if (response && response.status < 300) {

                this.setState({
                    dodaj: false,
                    pobrano: true,
                    uzytkownicy: response.data
                })

            } else {console.log('error1 towary')}
        }).catch((error) => {console.log('error2 towary')})
    }

    wybrany = towar =>{
        //console.log(towar);
        console.log('towar');
        //this.setState({wybrano:true, wybranytowar: towar})
    }

    onUsun=(user)=>{
        //alert(towar.ID)

        delUser(user.NUMER).then((response) => {
            if (response && response.status < 300) {

                console.log('pomyslnie usunieto uzytkownika');

                delUserKoszyk(user.NUMER).then((response) => {
                    if (response && response.status < 300) {

                        console.log('pomyslnie usunieto uzytkownika i jego koszyk')
                        toast.success("Usunięto użytkownika");
                        this.pobierztowary();

                    } else {console.log('error1 towary user')}
                }).catch((error) => {console.log('error2 towary user')})

            } else {console.log('error1 towary user')}
        }).catch((error) => {console.log('error2 towary user')})

    }

    usunuzytkownika = (user) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1 style={{textAlign: 'center'}}>Czy usunąć {user.IMIE} {user.NAZWISKO}?</h1>
                        <p style={{margin: '0 20px 20px 20px'}}>Spowoduje to usunięcie użytkownika i wykasowanie jego koszyka.</p>
                        <button style={{float: 'left', marginLeft: '20px'}} onClick={onClose}>NIE</button>
                        <button
                            style={{float: 'right', marginRight: '20px'}}
                            onClick={() => {
                                this.onUsun(user);
                                onClose();
                            }}
                        >
                            TAK, usuń użytkownika!
                        </button>
                    </div>
                );
            }
        });

    };

    onDodaj=()=>{
        insUser(this.state.imie, this.state.nazwisko, this.state.numer).then(res => {
            if (res.status === 200){

                this.setState({
                    dodaj: false
                });

                this.pobierztowary();
                toast.success("Dodano nowego użytkownika");
                console.log('insert ok');

                //this.props.history.push('/')
            } else {
                console.log('error')
            }
        }).catch(error => {
            console.log('Zle dane logowania');
        });
    }

    onChangeInput=(e)=>{
        this.setState({[e.target.name]: e.target.value})
    }

    onZamknij=()=>{
        this.setState({dodaj: false})
    }


    render(){
        const { pobrano, uzytkownicy, dodaj} = this.state;
        const listatowarow=uzytkownicy.map((element)=>(
            <li key={element.NUMER} onClick={() => this.wybrany(element)}>
                <div className="element"><i className="fa fa-trash" onClick={() => this.usunuzytkownika(element)}/><div className="naz"><h2>{element.IMIE} {element.NAZWISKO}</h2></div> <div className="censzt"><h5>tel: {element.NUMER}</h5></div></div>
            </li>
        ));
        return <>
            <Menu btext={'Koszyk'}/>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className={'main'}>

                {(dodaj)?
                    <Box title='Dodaj użytkownika' zamknij={this.onZamknij}>
                        <div id={'zmianahasla'}>
                            <div id={'in'}><label>Imie: <input type='text' name='imie' onChange={this.onChangeInput}/></label></div>
                            <div id={'in'}><label>Nazwisko: <input type='text' name='nazwisko' onChange={this.onChangeInput}/></label></div>
                            <div id={'in'}><label>Numer telefonu: <input type='text' name='numer' onChange={this.onChangeInput}/></label></div>
                            <div id={'in'}><label>Hasło: <input type='text' name='numer' value='123' disabled/></label></div>
                        </div>
                        <div className="dodaj-button">
                            <button onClick={this.onDodaj}>Dodaj użytkownika</button>
                        </div>
                    </Box>
                    :
                    <div></div>
                }

                <div className='kosz'>

                    <div className="kosznag">
                        <h3>Użytkownicy</h3>
                        <i id='dodaj' className="fa fa-user-plus" onClick={()=>this.setState({dodaj: true})}/>
                    </div>
                    {(pobrano)?
                        <div>
                            {(uzytkownicy.length===0 )?
                                <h4>Brak towarów...</h4>
                                :
                                <ul>{listatowarow}</ul>
                            }
                        </div>
                        :
                        <h4>Ładowanie...</h4>
                    }
                </div>

            </div>

            <Stopka title='Użytkownicy'/>
        </>
    }
}
