import React from 'react';

class Box extends React.Component {

    render(){
        return (
            <div className="towary" style={{background: '#3c5a86'}}>
                <div className="kosznag">
                    <h3>{this.props.title}</h3><i className="fa fa-times" onClick={()=>this.props.zamknij()}/>
                </div>
                {this.props.children}
            </div>
        );
    }

};


export default Box;
