import React from 'react'
import './login.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import {login} from "../../Serwis";
import {APP_VERSION} from "../../ApplicationConfig";

export default class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            login: '',
            password: ''
        }
    }

    onLogin(e){
        this.setState({login: e.target.value})
    }

    onPassword(e){
        this.setState({password: e.target.value})
    }

    onZaloguj(){

        login(this.state.password, this.state.login).then(res => {
                if (res.status === 200){
                    console.log(res)
                    let data = res.data
                    data.password = this.state.password
                    sessionStorage.setItem('sesjaUzytkownika', JSON.stringify({imie: res.data[0].IMIE, nazwisko: res.data[0].NAZWISKO, numer: this.state.login, dostep: res.data[0].DOSTEP}))
                    window.location.reload()
                    //this.props.history.push('/')
                } else {
                    console.log('error')
                }
            }).catch(error => {
            console.log('Zle dane logowania');
            toast.error("Błąd logowania!");
        });

        //sessionStorage.setItem('sesjaUzytkownika', JSON.stringify(this.state))
        //window.location.reload()
        //console.log('zalogowano')
    }

    render(){
        return (
                <div>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <div className="login-page">
                        <h3>Dar natury</h3>
                        <div className="form">
                            <div id={'in'}><i className="fa fa-user"/><input className={'form-control'} type={'text'} placeholder="Numer telefonu" onChange={this.onLogin.bind(this)}/></div>
                            <div id={'in'}><i className="fa fa-lock"/><input type={'password'} placeholder="Hasło" onChange={this.onPassword.bind(this)}/></div>
                            <button onClick={() => this.onZaloguj()}>Zaloguj</button>
                            <p id={'wersja'}>Wersja: {APP_VERSION()}</p>
                        </div>
                    </div>
                </div>

        )
    }
}
