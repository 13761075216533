import React from 'react'
import Menu from "../panel/Menu";
import Stopka from "../panel/Stopka";
import {toast, ToastContainer} from "react-toastify";
import {login, zmienHaslo} from "../../Serwis";

export default class Ustawienia extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            stare: '',
            nowe: '',
            nowep: ''
        }
    }

    onChangeInput=(e)=>{
        this.setState({[e.target.name]: e.target.value})
    }

    zmienHaslo=()=>{
        console.log('zgadza sieeee')

        zmienHaslo(this.state.nowe).then(res => {
            if (res.status === 200){

                this.setState({
                    stare: '',
                    nowe: '',
                    nowep: ''
                });

                toast.success("Hasło zostało zmienione");

                //this.props.history.push('/')
            } else {
                console.log('error')
            }
        }).catch(error => {
            console.log('Zmiana hasla blad');
        });
    }

    onZmien=()=>{
        if ((this.state.nowe===this.state.nowep) && (this.state.nowe))
        {
            login(this.state.stare, JSON.parse(sessionStorage.sesjaUzytkownika).numer).then(res => {
                if (res.status === 200){

                    this.zmienHaslo();

                } else {
                    console.log('error')
                }
            }).catch(error => {
                console.log('Zle dane logowania');
                toast.error("Złe stare hasło!");
            });
        }
        else
        {
            toast.error("Popraw nowe hasła!");
        }
    }

    render(){
        //const { pobrano, towary, razem, diler, dodaj} = this.state;
        return <>
            <Menu btext={'Koszyk'}/>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className={'main'}>
                <div className={'towary'}>
                    <div className={'kosznag'}>
                        <h3>Zmiana hasła</h3>
                    </div>
                    <div id={'zmianahasla'}>

                        <div id={'in'}><label>Stare hasło</label><input type={'password'} name='stare' value={this.state.stare} onChange={this.onChangeInput} required/></div>
                        <div id={'in'}><label>Nowe hasło</label><input type={'password'} name='nowe' value={this.state.nowe} onChange={this.onChangeInput} required/></div>
                        <div id={'in'}><label>Powtórz nowe hasło</label><input type={'password'} name='nowep' value={this.state.nowep} onChange={this.onChangeInput} required/></div>

                    </div>
                    <div className="dodaj-button">
                        <button onClick={this.onZmien}>Zmień hasło</button>
                    </div>
                </div>
            </div>

            <Stopka title='Ustawienia'/>
        </>
    }
}
