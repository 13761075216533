import './Style.scss';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Login from "./views/login/Login";
import Panel from "./views/panel/Panel";
import {isAdmin, isLogged} from "./Serwis";
import Ustawienia from "./views/ustawienia/Ustawienia";
import AdminPanel from "./views/adminpanel/AdminPanel";
import Uzytkownicy from "./views/adminpanel/Uzytkownicy";
import Magazyn from "./views/adminpanel/Magazyn";
import Termin from "./views/adminpanel/Termin";

function App() {
  return (
      <Router>
          <Switch>
              <Route exact path={'/'} component={isLogged()?Panel:Login}/>
              <Route exact path={'/ustawienia'} component={isLogged()?Ustawienia:Login}/>
              <Route exact path={'/adminpanel'} component={(isLogged() && isAdmin())?AdminPanel:Panel}/>
              <Route exact path={'/uzytkownicy'} component={(isLogged() && isAdmin())?Uzytkownicy:Panel}/>
              <Route exact path={'/magazyn'} component={(isLogged() && isAdmin())?Magazyn:Panel}/>
              <Route exact path={'/termin'} component={(isLogged() && isAdmin())?Termin:Panel}/>
          </Switch>
      </Router>
  );
}

export default App;
